@use "./../utils/variables.scss" as v;

#gallery-page {
  background-image: v.$gradient;

  h3 {
    color: white;
    font-size: 3.5rem;
    margin: 0 auto;
  }

  #top-gallery {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }

  .center {
    display: flex;
    justify-content: center;
    margin-bottom: 7rem;
  }

  .small-paragraph {
    font-size: 1rem;
    margin-top: -3rem;
    a {
      color: black;
      text-decoration: underline;
    }
  }
}
