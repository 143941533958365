@use "./../utils/variables.scss" as v;

nav {
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    list-style: none;

    #about-nav-links {
      display: flex;
      li {
        margin-right: 0.8rem;
      }
    }

    a {
      color: v.$main-color;
    }
  }
}
