@use "./../utils/variables.scss" as v;

#gallery-section {
  background-color: v.$second-color;
  justify-content: space-around;
  align-items: center;
  min-height: 53rem;

  .share-link {
    position: absolute;
    color: white;
    margin-left: 10rem;
    font-size: 2.5rem;
  }

  img {
    margin-top: 4rem;
    height: 30rem;
    object-fit: contain;
    transform: rotate(8.9deg);
  }
}
