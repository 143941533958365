@import url("https://fonts.googleapis.com/css2?family=Oleo+Script:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arapey&family=Oleo+Script:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arapey&family=Oleo+Script:wght@700&family=Roboto:ital,wght@0,700;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arapey&family=Oleo+Script:wght@700&family=Roboto:wght@300&display=swap");
*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Arapey", serif;
  line-height: 1.5;
  font-size: 20px;
}

.oleo {
  font-family: "Oleo Script", cursive;
  font-weight: 700;
}

h1 {
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  margin-top: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn {
  border: solid black 1px;
  padding: 0.5rem 2rem;
  width: fit-content;
  background-color: white;
  font-size: 1.5rem;
  color: black;
  border-radius: 4rem;
}

.no-outline {
  border: 0;
}

.section {
  padding: 2.2rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  max-height: 60rem;
  min-height: fit-content;
}

.imageFrame {
  border: 5px solid #ffffff;
  width: 95vw;
  height: 95vw;
  @media (min-width: 800px) {
    width: 500px;
    height: 500px;
  }
}

.arrow {
  color: black;
}

.filtered {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.rotated {
  transform: rotate(180deg);
}
