@use "./../utils/variables.scss" as v;

#generate-section {
  background-image: v.$gradient;
  justify-content: space-between;
  flex-grow: 1;
  min-height: 53rem;

  p {
    margin-top: 2.2rem;
  }

  .center {
    display: flex;
    justify-content: center;
  }

  .help-btn {
    background-color: transparent;
    border: none;
    color: black;
  }

  #loading {
    display: block;
    margin: 5rem auto;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: grey;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
}
