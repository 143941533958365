@use "./../utils/variables.scss" as v;

#header-section {
  background-image: v.$gradient;

  #header-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
  }

  #header {
    font-size: 6rem;
    margin-top: 3rem;
    line-height: 0.8;
    color: white;
  }

  a {
    margin: auto 0;
  }
}
