@use "./../utils/variables.scss" as v;

footer {
  padding: 2.2rem;
  display: flex;
  justify-content: space-between;
  background-color: white;
  max-height: fit-content;

  a {
    color: black;
  }

  p {
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    line-height: 1.1;
    margin: 0;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  img {
    height: 4rem;
    object-fit: contain;
  }
}
