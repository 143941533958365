@use "./../utils/variables.scss" as v;

#about-section {
  justify-content: space-between;
  flex-grow: 1;

  p {
    margin-top: 2.2rem;
  }

  .center {
    display: flex;
    justify-content: center;
  }

  .arrow {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .no-outline {
    margin-top: 2rem;
    background-color: v.$second-color;
  }
}
